.privacy-policy-modal tr:nth-of-type(2n) {
  background-color: rgba(0, 0, 0, 0.1);
}

.privacy-policy-modal th {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
}

.privacy-policy-modal td {
  padding: 0.5rem;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
