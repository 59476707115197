@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 10px;
    --global-color-primary-accent-normal: #63b890;
    --global-color-primary-accent-hovered: #82c6a6;
    scroll-padding-top: 14rem;
    scroll-behavior: smooth;
    height: 100%;
    scrollbar-gutter: stable;
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    background-color: #f2f2f7;
    height: 100%;
}

#root {
    height: 100%;
}

h1 {
    margin-top: 5rem;
    font-size: 3.2rem;
    font-weight: bold;
    margin-left: -35px;
    padding-left: 35px;
}

h2 {
    margin-top: 4.5rem;
    font-size: 2.4rem;
    font-weight: bold;
    margin-left: -35px;
    padding-left: 35px;
}

h3 {
    margin-top: 3.5rem;
    font-size: 2rem;
    font-weight: bold;
    margin-left: -35px;
    padding-left: 35px;
}

h4 {
    margin-top: 2.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: -35px;
    padding-left: 35px;
}

h5 {
    margin-top: 2rem;
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: -2.5rem;
    padding-left: 2.5rem;
}

h6 {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: -2.5rem;
    padding-left: 2.5rem;
}

p {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
}

ul,
ol {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

ul {
    list-style: outside disc;
}

ol {
    list-style: outside decimal;
}

.markdown ul,
.markdown ol {
    margin-left: 2.4rem;
}

.toc {
    overflow-y: auto;
    scrollbar-color: #c3c3c3 transparent;
}

.table-of-contents ul,
.table-of-contents ol {
    margin-left: 0;
}

tr {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

td {
    font-size: 1.6rem;
    border-right: 20px solid transparent;
    width: 50% !important;
    vertical-align: top;
}

li {
    font-size: 1.6rem;
    margin-top: 0.4rem;
    margin-bottom: 0.8rem;
}

.alert div p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

li img {
    width: 2rem !important;
    height: 2rem !important;
}

a {
    color: black;
    font-weight: inherit;
    text-decoration: underline;
    text-decoration-color: var(--global-color-primary-accent-hovered);
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
}

.table {
    width: 100%;
}

.table tr {
    border: 1px solid black;
}

.table th {
    text-align: left;
    padding: 5px;
    border-right: 1px solid black;
    width: auto !important;
}

.table td {
    border: none;
    border-right: 1px solid black;
    padding: 5px;
    width: auto !important;
}

a:hover {
    color: var(--global-color-primary-accent-hovered) !important;
}

.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
}

summary h6 {
    display: inline;
    cursor: pointer;
}

details {
    margin-bottom: 1.5rem;
}

.fade-in {
    animation: 0.5s ease-in-out fadeIn;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut 0.35s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
