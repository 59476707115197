.table-of-contents a {
    color: var(--global-color-primary-accent-normal);
    font-weight: 500;
    text-decoration: none;
}

.table-of-contents a:hover {
    color: var(--global-color-primary-accent-hovered);
}

.table-of-contents .toc {
    padding-left: 0;
    width: 100%;
}

.table-of-contents .toc ol {
    list-style: none;
}

.table-of-contents .toc .toc-item-h1 a {
    width: 100%;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
}

.table-of-contents .toc .toc-item-h2 a,
.table-of-contents .toc .toc-item-h3 a,
.table-of-contents .toc .toc-item-h4 a,
.table-of-contents .toc .toc-item-h5 a,
.table-of-contents .toc .toc-item-h6 a {
    width: 100%;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
}

.table-of-contents .toc-level {
    margin-top: 0.4rem;
}

.toc-level-2,
.toc-level-3,
.toc-level-4,
.toc-level-5,
.toc-level-6 {
    padding-left: 20px;
}

.table-of-contents .toc-item {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.table-of-contents .toc-item-h1 {
    font-size: 2rem;
    margin-top: 2rem;
}

.table-of-contents .toc-item-h1:first-of-type {
    margin-top: 0 !important;
}

.table-of-contents .toc-item-h1 a:first-of-type {
    font-weight: 500;
}

.table-of-contents .toc-item-h2 {
    font-size: 1.6rem;
    margin-top: 1rem;
}

.table-of-contents .toc-item-h2:first-of-type {
    margin-top: 0 !important;
}

.table-of-contents .toc-item-h3 {
    font-size: 1.4rem;
}

.table-of-contents .toc-item-h4 {
    font-size: 1.2rem;
}

.table-of-contents .toc-item-h5 {
    font-size: 1rem;
}

.anchor {
    position: absolute;
    margin-left: -35px;
    width: 30px;
    text-align: right;
    text-decoration: none;
    visibility: hidden;
}

h1:hover .anchor,
h2:hover .anchor,
h3:hover .anchor,
h4:hover .anchor {
    visibility: visible;
}
