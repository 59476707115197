.newsletter-section h2:first-of-type {
    margin-top: 0;
}

.react-tabs__tab-list {
    display: flex;
}

.react-tab {
    cursor: pointer;
    list-style-type: none;
    color: var(--global-color-primary-accent-normal);
    background: rgba(99, 184, 144, 0.1);
    outline: none !important;
}

.react-tabs__tab:hover {
    color: var(--global-color-primary-accent-hovered);
}

.react-tabs__tab--selected {
    color: rgb(16 48 98);
    background: #ffffff;
}

.mailchimp-form h1 {
    color: white !important;
}

.mailchimp-form h2 {
    color: white !important;
}

.mailchimp-form h3 {
    margin-top: 0;
    font-weight: 500;
    color: white !important;
}

.mailchimp-form p {
    color: white !important;
}

#mc_embed_signup {
    clear: left;
    font-size: 1.4rem;
    width: 100%;
    max-width: 1200px;
}

#mc_embed_signup form {
    margin: 0 !important;
    color: black !important;
    accent-color: var(--global-color-primary-accent-normal);
}

#mc_embed_signup .mc-field-group {
    padding: 0 !important;
}

#mc-embedded-subscribe-form input[type="checkbox"] {
    display: inline;
    width: auto;
    margin-right: 10px;
}

.mc-field-group-label {
    color: white;
}

label.checkbox.subfield span {
    color: white !important;
}

#mergeRow-gdpr {
    margin-top: 2rem !important;
    max-width: 100% !important;
}

#mergeRow-gdpr fieldset label {
    font-weight: normal;
}
#mc-embedded-subscribe-form .mc_fieldset {
    border: none;
    min-height: 0px;
    padding-bottom: 0px;
}

#mc_embed_signup .mc-field-group label {
    margin: 0 !important;
}

label.checkbox.subfield {
    display: flex !important;
    align-items: center !important;
    font-size: 1.8rem;
}

.indicates-required {
    max-width: 360px;
}

#mc_embed_signup .mc-field-group {
    max-width: 360px;
}

#mc_embed_signup input[type="checkbox"] {
    width: 2rem !important;
    height: 2rem !important;
}

#mc_embed_signup .helper_text {
    background-color: transparent !important;
}

#mc_embed_signup .button {
    background-color: var(--global-color-primary-accent-normal) !important;
    border-radius: 100rem !important;
    height: 4rem !important;
    min-width: 144px !important;
    font-size: 1.4rem !important;
    font-weight: bold !important;
}

#mc_embed_signup .button:hover {
    background-color: var(--global-color-primary-accent-hovered) !important;
}

.content__gdprLegal a {
    color: white;
}
